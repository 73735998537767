/* ==========================================================================
Mockups
========================================================================== */

'use strict'

function initMockup() {
  $('#show-video, #show-mockup').on('click', function () {
    $('#show-video, #show-mockup').toggleClass('is-hidden')
    $('#video, #mockup').toggleClass('is-hidden')
  })
  $('#show-video1, #show-mockup1').on('click', function () {
    $('#show-video1, #show-mockup1').toggleClass('is-hidden')
    $('#video1, #mockup1').toggleClass('is-hidden')
  })
}
